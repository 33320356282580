<template>
  <div>
    <h5>Organizations</h5>
    <div v-if="loading" class="border-dashed border-2 rounded-lg p-2 mt-3">
      <LoadingSpinner />
    </div>
    <div v-else class="border-dashed border-2 rounded-lg p-2 mt-3">
      <b-row>
        <b-col>
          <div class="flex flex-row">
            <p class="mt-2 ml-2 mb-1">{{ primaryOrganization.name }} (Primary)</p>
          </div>
        </b-col>
      </b-row>
      <b-row v-for="organization in organizations" :key="organization.id">
        <b-col>
          <div class="flex flex-row">
            <button
              class="p-2 focus:ring focus:ring-green-200 rounded-lg"
              v-b-modal="`removeOrgModal-${organization.id}`"
              v-if="isRbAdmin"
            >
              <font-awesome-icon class="text-l" :icon="['far', 'trash-can']" />
              <span class="sr-only">Delete</span>
            </button>
            <p class="mt-2 ml-2 mb-1" :class="{ 'ml-1 mb-0': isRbAdmin }">{{ organization.name }}</p>
          </div>
        </b-col>
        <b-modal
          :id="`removeOrgModal-${organization.id}`"
          title="Remove Organization"
          centered
          @ok="removeOrganization(organization.id)"
        >
          <p class="text-center py-3">
            Are you sure you wish to remove the organization
            <span class="font-bold">{{ organization.name }} </span> from this user?
          </p>
          <template #modal-footer="{ ok, cancel }">
            <b-button @click="cancel()">Cancel</b-button>
            <b-button variant="danger" @click="ok()">Remove</b-button>
          </template>
        </b-modal>
      </b-row>
    </div>
  </div>
</template>

<script>
import OrganizationServiceV2 from '@/lib/organization-service-v2';
import UserService from '@/lib/user-service-v2';
import LoadingSpinner from '@/components/rbComponents/LoadingSpinner.vue';

export default {
  props: {
    userId: {
      type: String
    },
    primaryOrganizationId: {
      type: String
    },
    secondaryOrganizations: {
      type: Array
    },
    isRbAdmin: {
      type: Boolean
    }
  },
  components: {
    LoadingSpinner
  },
  data() {
    return {
      organizations: [],
      primaryOrganization: null,
      loading: true
    };
  },
  watch: {
    organizations() {
      // ensure all organizations are retrieved before turning the loading spinner off
      if (this.secondaryOrganizations.length === this.organizations.length) {
        this.loading = false;
      }
    }
  },
  async beforeMount() {
    try {
      this.primaryOrganization = await OrganizationServiceV2.retrieveOrganization(this.primaryOrganizationId);
    } catch (error) {
      this.$emit('error', `Failed to retrieve organization, ${this.parseError(error).message}`);
    }

    if (this.secondaryOrganizations.length > 0) {
      this.secondaryOrganizations.forEach((id) => {
        this.getOrganizationName(id);
      });
    } else {
      this.loading = false;
    }
  },
  methods: {
    async getOrganizationName(id) {
      try {
        const result = await OrganizationServiceV2.retrieveOrganization(id);
        this.organizations.push({ name: result.name, id: result.id });
        this.organizations.sort((first, second) => {
          return first.name.localeCompare(second.name);
        });
      } catch (error) {
        this.$emit('error', `Failed to retrieve organization, ${this.parseError(error).message}`);
      }
    },
    async removeOrganization(organizationId) {
      try {
        await UserService.removeOrganization(this.userId, organizationId);

        // Manually remove org from array to avoid forcing a reload of the component. Org will no longer be here on next proper load anyway
        this.organizations = this.organizations.filter((org) => org.id !== organizationId);
        this.$emit('organizationRemoved', organizationId);
      } catch (error) {
        this.$emit('error', `Failed to remove organization, ${this.parseError(error).message}`);
      }
    }
  }
};
</script>
