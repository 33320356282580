<template>
  <div>
    <b-container class="mb-7">
      <b-row>
        <b-col>
          <h1>
            <span class="text-muted">User ></span>
            Details
          </h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <Alert variant="red" icon="exclamation" v-if="errorMessage">{{ errorMessage }}</Alert>
          <Alert variant="green" icon="check" dismissable v-if="updateSuccess">{{ updateSuccessMessage }}</Alert>
        </b-col>
      </b-row>
      <b-row class="mb-5" v-if="userReady">
        <b-col cols="6">
          <b-card class="user-card">
            <UserEdit :user="user" />
          </b-card>
        </b-col>
        <b-col cols="6">
          <b-card>
            <MultipleOrganizationSelect :user="user" @userUpdated="onUserUpdated" @error="onError" v-if="isRbAdmin" />
            <UserOrgList
              :userId="user.id"
              :primaryOrganizationId="user.organizationId"
              :secondaryOrganizations="user.secondaryOrganizations"
              :isRbAdmin="isRbAdmin"
              :key="reload"
              @error="onError"
              @organizationRemoved="onOrganizationRemoved"
              :class="{ 'mt-5': isRbAdmin }"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import UserEdit from '@/components/UserEdit';
import MultipleOrganizationSelect from '@/components/MultipleOrganizationSelect';
import UserOrgList from '@/components/UserOrgList';
import UserService from '@/lib/user-service-v2';
import { getAuth, Roles } from '@rafflebox-technologies-inc/auth-service-sdk';
import Alert from '@/components/ui/Alert.vue';

export default {
  components: {
    Alert,
    UserEdit,
    MultipleOrganizationSelect,
    UserOrgList
  },
  data() {
    return {
      errorMessage: null,
      user: {},
      userReady: false,
      updateSuccess: false,
      updateSuccessMessage: '',
      reload: 0,
      isRbAdmin: false
    };
  },
  async created() {
    const sessionUser = await getAuth().sessionUser();
    this.isRbAdmin = sessionUser.hasRole(Roles.RB_ADMIN);

    await this.getUser();
  },
  methods: {
    async getUser() {
      try {
        const userData = await UserService.retrieveUser(this.$route.query.id);

        this.user = {
          id: userData.id,
          firstName: userData.firstName,
          lastName: userData.lastName,
          username: userData.provinceState,
          email: userData.email,
          roles: userData.roles,
          password: userData.phone,
          confirmPassword: userData.email,
          frozen: userData.frozen,
          disabled: userData.disabled,
          organizationId: userData.organizationId,
          secondaryOrganizations: userData.secondaryOrganizations
        };

        this.userReady = true;
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
    },
    forceReload() {
      this.reload++;
    },
    onOrganizationRemoved(orgId) {
      this.user = {
        ...this.user,
        secondaryOrganizations: this.user.secondaryOrganizations.filter((id) => id !== orgId)
      };
      this.updateSuccess = true;
      this.updateSuccessMessage = 'Organization removed successfully!';
    },
    async onUserUpdated() {
      this.updateSuccess = true;
      this.updateSuccessMessage = 'User updated successfully!';
      // Refresh user data to get latest organizations, then force UserOrgList to reload so it has the latest too.
      await this.getUser();
      this.forceReload();
    },
    onError(error) {
      this.errorMessage = error;
    }
  }
};
</script>
